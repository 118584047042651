/*Overwrites CSS styles which apply to this instance of MaterialTable which cannot be handled by the componenets build-in styles mechanism : 
https://material-table.com/#/docs/features/styling
*/


/* Add border to first alarm entry (since header is not shown in mobile view)*/
.deviceAlarmsMaterialTableOverwrite tbody tr:first-of-type td {
    border-top-style: solid;
    border-top-color: rgb(224, 224, 224);
    border-top-width: 1px;
}

/* Reduce the padding between table elements in in mobile view*/
.deviceAlarmsMaterialTableOverwrite  .smallerPadding .MuiTableCell-root {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
} 