body {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
}

:root {
  --amplify-font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  --amplify-primary-color: #c1002a;
  --amplify-primary-tint: #c1002a;
  --amplify-primary-shade: #c1002a;
}
